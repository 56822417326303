import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiConfig } from '@config/api.config';
import { Store } from '@ngrx/store';
import { StoreStates } from '@reducers/store-states';
import { MiscTools } from '@tools/misc.tools';
import { Observable, throwError as observableThrowError, of, Subject } from 'rxjs';
import { catchError, delay, expand, filter, map, takeUntil } from 'rxjs/operators';

/*
 * Note about the API this service is designed for:
 * -------------------------------------------------
 * This service is designed for an API which must be polled for information. So if
 * you want to get data from the endpoint, it might not be there (yet). Therefore
 * you must poll the API until det response is valid (isDataReady: 'OK').
 *
 * Some endpoints should only be requested one time, and if the data isn't there
 * (yet), it starts the process of fetching it (from an external source). Since
 * it only should be requested one time, we need a fallback endpoint, which should
 * be used to check the status of the first request (if data is available -
 * isDataReady: 'OK').
 *
 * If your endpoint can be requested multiple times on the same URL, you don't
 * need to supply the (optional) fallBackUrl parameter.
 */

const TIMEOUT_LIMIT = 426; // equals about 30 min

const TOKEN_SIZE = 8;

@Injectable()
export class EndPointService {
  private http = inject(HttpClient);
  private requestIsCancelled = true;

  private stopRequests: Subject<void>;
  private store = inject<Store<StoreStates>>(Store);
  sessionId: string;

  constructor() {
    this.sessionId = MiscTools.tokenGenerator(TOKEN_SIZE);
  }

  /**
   * Extract data from Response object as JSON
   * @param  {any}       body   response from the Observable to be parsed
   * @return {JSON | any}                 returns the JSON representation of the object
   */
  private extractData(body: any): any | JSON {
    body.jsonMapDoc = body.jsonMapDoc || {};
    // parse jsonMapDoc as JSON (if possible - if not empty)
    if (Object.keys(body.jsonMapDoc).length > 0) {
      try {
        body.jsonMapDoc = JSON.parse(body.jsonMapDoc);
      } catch {
        return {};
      }
      return body || {};
    } else {
      return body || {};
    }
  }

  /**
   * Parse error and return as Observable (throw it - must be caught)
   * @param  {Response}       error     error response from the Observable to be parsed
   * @return {Observable}               returns an Observable containing the error message
   */
  private handleError(error: any | Response) {
    let errMsg: string;

    if (error instanceof Response) {
      const body = error || '';
      const err = body['error'] || JSON.stringify(body);

      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return observableThrowError(errMsg);
  }

  cancelRequests() {
    // console.log('@@@@@@@@ cancel Requests @@@@@@@@@');
    this.requestIsCancelled = true;
    // stop getData request:
    this.stopRequests.next();
    this.stopRequests.complete();
    // stop requests in area-statistics component:
    this.store.dispatch({ payload: false, type: 'UPDATE_LOADING_STATE' });
  }

  /**
   * Fetch the 'arealstatistikk'. The 'statType' specifies what
   * endpoint to fetch data from. http-post request is used to
   * query the endpoint.
   * @param  {any}        params   Request parameters (body of post request)
   * @param  {string}     url Specifies the endpoint (based on what
   *                               kind of 'arealstatistikk')
   * @return {Observable}          An Observable of the response from the endpoint
   */
  fetchGardskart(params: any, url: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const options = { headers: headers };
    params.usersessionIdType = this.sessionId;
    const body = JSON.stringify(params);
    return this.http.post(url, body, options).pipe(map(this.extractData), catchError(this.handleError));
  }

  /**
   * Get all the municipalities
   * @return {Observable<any>} Observable of the returned municipalities
   */
  getAllMunicipalities(): Observable<any> {
    const url = ApiConfig.kommuneListe;
    return this.http.get(url);
  }

  /**
   * Recursive request to given url (and optionally fallBackUrl) using getHttp,
   * until the value of isDataReady is 'OK' or  isDataReady is OLD_DATA (meaning the data is valid)
   * @param  {string}          endPointUrl    main point of request to the API
   * @param  {Array<string>}   states         data-states to accept
   * @param  {string}          fallBackUrl    optional fallback endpoint of the API (check for status
   *                                          without making new request)
   * @return {Observable<any>}                returns an Observable with the data (mapped to JSON)
   */
  getData(endPointUrl: string, fallBackUrl = '', states: string[] = ['OK']): Observable<any> {
    if (!this.requestIsCancelled && this.stopRequests) {
      this.stopRequests.next();
      this.stopRequests.complete();
    }
    let counter = 0;
    let checkStatus = { lib: true, matrikkel: true };
    this.requestIsCancelled = false;
    this.stopRequests = new Subject<void>();
    let libErrorCount = 0;
    return this.getHttp(endPointUrl).pipe(
      takeUntil(this.stopRequests),
      map(this.extractData),
      expand(data => {
        if (
          states.indexOf(data.isDataReady) < 0 &&
          !this.requestIsCancelled &&
          libErrorCount < ApiConfig.LIB_ERRORCOUNT_LIMIT &&
          checkStatus.matrikkel
        ) {
          // data is not valid, continue checking endpoint
          checkStatus = MiscTools.check_status_Lib_matrikkel(data);
          if (checkStatus.lib === false) {
            libErrorCount++;
            console.log('##### Lib error, errorCount er:', libErrorCount);
          }

          // limit the number of request, before a timeout happens
          if (++counter > TIMEOUT_LIMIT) {
            return observableThrowError('Ingen data tilgjengelig, da tjenesten ikke svarer');
          }

          // check if fallBackUrl is given, then all consecutive
          // requests should be sent to that endpoint.
          if (fallBackUrl !== '') {
            // request the fallback endpoint
            // increase delay time with regards to amount of attempts
            return this.getHttp(fallBackUrl).pipe(map(this.extractData), delay(MiscTools.getDelay(counter)));
          } else {
            // request the main endpoint
            // increase delay time with regards to amount of attempts
            return this.getHttp(endPointUrl).pipe(map(this.extractData), delay(MiscTools.getDelay(counter)));
          }
        } else {
          // data is valid, return empty Observable to quit the expand()-operator
          return of();
          // return observableThrowError('Ingen data tilgjengelig');
        }
      }), // only expose responses which is specified in states
      filter(data => data && states.indexOf(data['isDataReady']) > -1),
      catchError(this.handleError),
    );
  }

  /**
   * Get data from endpoint using http get request. Returns an unprocessed Observable.
   * @param  {string}             endpointUrl   URL of the endpoint to fetch data from
   * @param requestOptions
   * @return {Observable<any>}                  unprocessed Observable
   */
  getHttp(endpointUrl: string, requestOptions: any = ''): Observable<any> {
    const defaultOptions = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Cache-Control': 'no-cache, no-store, must-revalidate',

      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',

      Pragma: 'no-cache',
    };

    const headers = new HttpHeaders(requestOptions === '' ? defaultOptions : requestOptions);

    const options = { headers: headers };

    const separatorCharacter: string = endpointUrl.indexOf('?') !== -1 ? '&' : '?';

    endpointUrl =
      endpointUrl + separatorCharacter + 'client_session_id=' + this.sessionId + '&max_age=' + ApiConfig.max_age;

    this.stopRequests = new Subject<void>();
    return this.http.get(endpointUrl, options).pipe(takeUntil(this.stopRequests));
  }

  isRequestCancelled(): boolean {
    return this.requestIsCancelled;
  }

  /** Recursive request to given url (and optionally fallBackUrl) using postHttp,
   *  until the value of isDataReady is 'OK'  or isDataReady is OLD_DATA (meaning the data is complete,
   *  but maybe not of the newest version)
   * @param  {string}           endPointUrl     main point of request to the API
   * @param  {any}              requestBody     request to the endpoint (as JSON)
   * @param  {string}           fallBackUrl     optional fallback endpoint of the API (check for status
   *                                            without making new request)
   * @param  {Array<string>}    states         optional array of isDataReady-states to accept, default ['OK']
   * @return {Observable<any>}                  returns an Observable with the data (mapped to JSON)
   */
  postData(endPointUrl: string, requestBody: any, fallBackUrl = '', states: string[] = ['OK']): Observable<any> {
    let counter = 0;
    let checkStatus = { lib: true, matrikkel: true };
    let libErrorCount = 0;
    this.requestIsCancelled = false;
    return this.postHttp(endPointUrl, requestBody).pipe(
      expand(data => {
        // Check if isDataReady is not in the acceptable states
        if (
          states.indexOf(data.isDataReady) < 0 &&
          !this.requestIsCancelled &&
          libErrorCount < ApiConfig.LIB_ERRORCOUNT_LIMIT &&
          checkStatus.matrikkel
        ) {
          // data is not valid, continue checking endpoint

          checkStatus = MiscTools.check_status_Lib_matrikkel(data);
          if (checkStatus.lib === false) {
            libErrorCount++;
            console.log('##### Lib error, errorCount er:', libErrorCount);
          }

          // limit the number of request, before a timeout happens
          if (++counter > TIMEOUT_LIMIT) {
            return observableThrowError('Ingen data tilgjengelig, da tjenesten ikke svarer');
          }

          // check if fallBackUrl is given, then all consecutive
          // requests should be sent to that endpoint.
          if (fallBackUrl !== '') {
            // request the fallback endpoint
            return this.postHttp(fallBackUrl, requestBody).pipe(delay(MiscTools.getDelay(counter)));
          } else {
            // request the main endpoint
            return this.postHttp(endPointUrl, requestBody).pipe(delay(MiscTools.getDelay(counter)));
          }
        } else {
          return observableThrowError('Ingen data tilgjengelig');
        }
      }),
      filter(data => data && states.indexOf(data['isDataReady']) > -1),
      catchError(this.handleError),
    );
  }

  /**
   * Get data from endpoint using http post request. Returns an unprocessed Observable.
   * @param  {string}          endpointUrl  URL of the endpoint to fetch data from
   * @param  {any}             requestBody  Request body
   * @param  {any          =           ''}          requestOptions description
   * @param {any}                           responseType
   * @return {Observable<any>}             description
   */
  postHttp(endpointUrl: string, requestBody: any, requestOptions: any = '', responseType: any = ''): Observable<any> {
    const defaultOptions = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',

      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',

      Pragma: 'no-cache',
    };

    const headers = new HttpHeaders(requestOptions === '' ? defaultOptions : requestOptions);

    const options = { headers: headers };

    if (responseType !== '') {
      options['responseType'] = responseType;
    }

    return this.http.post(endpointUrl, requestBody, options).pipe(catchError(this.handleError));
  }
}
